<template>
  <div class="json-label-group-main">
      <el-button @click="openAddGroupDialog" class="label-group-add" :icon="Plus" circle style="margin-bottom: 10px;margin-top:6px;"/>
      <div class="cad-list">
        <el-card @click="clickGroup(item.guid)" v-for="item in groupList" :style="item.isActive ? groupActiveStyle : groupNotActiveStyle" class="box-card">
          <span class="group-name">{{ item.name }}</span>
        </el-card>
      </div>
<!--    <div class="container">-->
<!--      <div class="text-rain-effect"></div> &lt;!&ndash; 新增文字雨特效容器 &ndash;&gt;-->
<!--    </div>-->

<!--    <CloudTest />-->



      <!-- 添加group的模态框 -->
      <el-dialog
          v-model="addGroupDialogShow"
          title="添加group"
          width="30%"
          :before-close="handleAddGroupDialogClose"
          :center="false"
      >
          <el-input v-model="groupName" placeholder="请输入group的名称" />
          <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleAddGroupDialogClose">取消</el-button>
                <el-button type="primary" @click="confirmAddGroupDialog">
                  确定
                </el-button>
              </span>
          </template>
      </el-dialog>

  </div>
</template>

<script setup name="JsonLabelGroup">
import {ref, reactive, computed, nextTick} from 'vue'
import { useStore } from 'vuex'
import {
    Plus,
} from '@element-plus/icons-vue'
import {commonUtils} from "@/libs/util";

// import CloudTest from "@/views/jsonpage/cloudTest.vue";


const $store = useStore()
const groupList = ref(computed(() => {return $store.state.groupList}))
const groupActiveStyle = ref('height: 60px;width: 50px;margin-top: 10px;border: dashed 2px;')
const groupNotActiveStyle = ref('height: 60px;width: 50px;margin-top: 10px;')
const addGroupDialogShow = ref(false)
const groupName = ref('')
const windowsHeight = ref(0)


// 打开添加group的模态框
const openAddGroupDialog = () => {
    addGroupDialogShow.value = true
}

// 关闭添加group的模态框
const handleAddGroupDialogClose = () => {
    addGroupDialogShow.value = false
    groupName.value = ''
}

// 确定添加group的模态框
const confirmAddGroupDialog = () => {
    let newTabObj = {
        title: "Tab  " + "1",
        name: commonUtils.generateUUID(),
        highLightColor: "black",
        inputJsonStr: "请输入json"
    }
    let newGroupObj = {
        name: groupName.value,
        guid: commonUtils.generateUUID(),
        isActive: false,
        isFixed: false,
        tabList: [newTabObj],
        activeTabGuid: newTabObj.name,
        tabNameSerial: 2
    }
    groupList.value.push(newGroupObj)
    $store.state.activeGroupGuid = newGroupObj.guid
    clickGroup(newGroupObj.guid)
    handleAddGroupDialogClose()
}

// 点击group
const clickGroup = (itemGuid) => {
    for (let obj of groupList.value) {
        if (obj.guid == itemGuid) {
            obj.isActive = true
            $store.state.activeGroupGuid = obj.guid
        } else {
            obj.isActive = false
        }
    }
    console.log("clickGroup处理完后的groupList", JSON.stringify(groupList.value))
}

// 页面初始化时，获得页面高度
nextTick(()=>{
  windowsHeight.value = document.documentElement.clientHeight;
})

window.onresize = () => {
  return (
      () => {
        windowsHeight.value = document.documentElement.clientHeight;
      }
  )()
}

</script>
<style scoped>
.json-label-group-main {
    text-align: center;
}

:deep(.el-dialog__title) {
    float: left;
}

.box-card {
    cursor: pointer;
    caret-color: transparent; /* 去掉跳动的光标 */
}

.group-name {
    font-size: 10px;
}

.cad-list {

}
</style>