<template>
    <div>
        <div class="login-div">
          <el-form class="login-form" :model="loginForm" label-width="120px">
            <el-form-item label="昵称">
              <el-input v-model="loginForm.nickName" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input type="password" v-model="loginForm.password" />
            </el-form-item>
          </el-form>
          <el-button @click="login" class="login-btn" type="primary">确认登录</el-button>
        </div>
    </div>
</template>

<script setup name="MemberLogin">
import {ref, reactive} from 'vue'
import {ElMessage} from "element-plus";


const loginForm = ref({})



const login = () => {
  console.log("点击登录")
  let judge = formVerify()
  console.log("登录表单验证结果", judge)
  if (!judge) {
    return
  }
}

const formVerify = () => {
  if (!loginForm.value.nickName) {
    ElMessage({
      type: 'error',
      message: '昵称为空！',
    })
    return false
  }
  if (!loginForm.value.password) {
    ElMessage({
      type: 'error',
      message: '密码为空！',
    })
    return false
  }

  return true
}

</script>
<style scoped>
.login-div {
}
.login-btn {
  float: right;
  margin-bottom: 10px;
}
</style>
