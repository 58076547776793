<template>
  <div>
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
      >
          <el-menu-item index="1" class="logo">
              <img src="../../assets/logotemp.jpg">
              <span :style="titleStyle">&nbsp;codingoal</span>
          </el-menu-item>
          <el-menu-item index="2">
              <span class="title-font">json格式化</span>
          </el-menu-item>
          <el-menu-item index="3">
              <span class="title-font">登录开启云存储</span>
          </el-menu-item>
        <el-menu-item index="5">
          <span class="title-font">在线二维码</span>
        </el-menu-item>
          <el-menu-item index="4">
              <span class="title-font">功德作坊</span>
          </el-menu-item>
      </el-menu>
  </div>
</template>

<script setup name="nav">
import {commonUtils} from "@/libs/util";
import {ref,reactive,onMounted} from 'vue'
import { useRouter } from 'vue-router'

const titleStyle = ref("font-size: 2em;color:black;")
const $router = useRouter()
const activeIndex = ref("2")

const handleSelect = (key, keyPath) => {
    switch (key) {
        case "2":
            $router.push('JsonLabel')
            break
        case "3":
            $router.push('MemberMain')
            break
        case "4":
            $router.push('MoreMain')
            break
        case "5":
          $router.push('QRCodeMain')
          break
    }
}

const changeStyle = () => {
    // setInterval(() => {
    //     titleStyle.value = "font-size: 2em;font-weight:bold;color:" + commonUtils.randomColor()
    // }, 300)
}

onMounted(() => {
    changeStyle()
})




</script>

<style scoped>
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 0px !important;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: white !important;
}
.el-menu-item {
    border-bottom: 0px !important;
}
.logo > span {
    font-size: 2em;
    font-weight:bold;
}

.logo > img {
    width: 17%;
}

.logo {
    width: 10%;
}

.title-font {
    font-size: 1.2em;
}
</style>