<template>
  <div class="container">
      <el-card class="left-card" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="fish" @click="clickFish">
            <img class="click" v-if="clickFlag" src="../../assets/woodenFish.gif" alt="" />
            <img v-if="!clickFlag" src="../../assets/woodenFish.gif" alt="" />
            <div class="text">
                <span v-for="item in meritsTextArr">功德+1</span>
            </div>
        </div>
      </el-card>
      <el-card class="right" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div>{{meritsTextArr}}</div>
      </el-card>
  </div>
</template>

<script setup name="MeritsAndVirtues">
import {ref,reactive,onMounted} from 'vue'
import { useStore } from 'vuex'

const $store = useStore()

const clickFlag = ref(false)
const showGongde = ref(false)
const meritsTextArr = ref([])
const baseCount = ref(0)


const clickFish = () => {
    clickFlag.value = true

    setTimeout(() => {
        clickFlag.value = false
    }, 500)

    showGongde.value = true

    let newBaseCount = baseCount.value + 1
    baseCount.value = newBaseCount
    meritsTextArr.value.push(newBaseCount)
    setTimeout(() => {
        // showGongde.value = false
        meritsTextArr.value.shift()
    }, 1500)

}


onMounted(() => {
})

</script>
<style scoped>
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    height: 45rem;
}
.left-card {
    background-color: black;
    height: 100%;
    cursor: url(../../assets/WoodenStick.gif);
}
.fish {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.click {
    animation: am ease 0.5s;
}
@keyframes am {
    0% {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(0.98, 0.98);
    }

    60% {
        transform: scale(1.01, 1.01);
    }

    100% {
        transform: scale(1, 1);
    }
}

.text {
    position: relative;
}

.text span {
    color: #fff;
    font-size: 20px;
    width: 200px;
    position: absolute;
    top: -120px;
    left: -20px;
    opacity: 0;
    animation: am2 ease-out 1.5s;
}

@keyframes am2 {
    from {
        opacity: 0;
        transform: translateY(0);
    }
    to {
        transform: translateY(-35px);
        opacity: 1;
    }
}
</style>