
export class CommonUtils {

     randomColor = () => {
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        return "rgb(" + r + "," + g + "," + b + ")";
    }

    randomMotto = (type) => {
         let moottoArr = [
             "远方很远，步履不停。未来可期，努力发光！",
             "犹豫吃不吃的时候，不吃；犹豫做不做的时候，去做。",
             "成功与借口，永远不会住在同一个屋檐下！",
             "人生重要的不是所站的位置，而是所朝的方向。",
             "不为模糊不清的未来担忧，只为清清楚楚的现在努力。",
             "山中也有千年树，世上难逢百岁人。",
             "平生莫做皱眉事，世上应无切齿人。",
             "求人需求大丈夫，济人须济急时无。",
             "出家如初，成佛有余。",
             "责人之心责己，恕己之心恕人。",
             "宁可人负我，切莫我负人；再三须重事，第一莫欺心。",
             "虎生犹可近，人熟不堪亲。",
             "来说是非者，便是是非人。",
             "守口如瓶，防意如诚。",
             "力微休负重，言轻莫劝人；无钱莫入众，遭难莫寻亲。",
         ]
        let index = Math.floor(Math.random() * 14);
         return moottoArr[index]
    }

    checkIsJsonStr = (str) => {
        if (typeof str == 'string') {
            try {
                let obj = JSON.parse(str);
                return true;
            } catch(e) {
                return false;
            }
        }
    }


    getNowTimeStr = () => {
        let date = new Date();//当前时间
        let year = date.getFullYear() //返回指定日期的年份
        function repair(number) {
            if (number >= 0 && number <= 9) {
                return "0" + number;
            } else {
                return number;
            }
        }

        let month = repair(date.getMonth() + 1);//月
        let day = repair(date.getDate());//日
        let hour = repair(date.getHours());//时
        let minute = repair(date.getMinutes());//分
        let second = repair(date.getSeconds());//秒

        //当前时间
        let curTime = year + "-" + month + "-" + day
            + " " + hour + ":" + minute + ":" + second;
        return curTime;
    }

    // 生成uuid
    generateUUID = () => {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        let uuid = s.join("");
        return uuid;
    }

    // 获取当前的group
    getActiveGroup = (groupList, activeGroupGuid) => {
         for (let obj of groupList) {
             if (obj.guid == activeGroupGuid) {
                 return obj
             }
         }
         return null
    }

    // 获取当前的tab
    getActiveTab = (activeGroup) => {
         for (let obj of activeGroup.tabList) {
             if (obj.name == activeGroup.activeTabGuid) {
                 console.log("查询当前tab:", obj)
                 return obj
             }
         }
         return null
    }



}

export const commonUtils = new CommonUtils()