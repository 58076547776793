import { createStore } from 'vuex'
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import {commonUtils} from "@/libs/util";

export default createStore({
    state: {
        editableTabs: [
            {
                title: "Tab  " + commonUtils.getNowTimeStr(),
                name: '1',
                highLightColor: "black",
                inputJsonStr: "请输入json"
            },
        ],
        selectedTabs: [],
        curGroup: {},
        groupList: [],
        activeGroupGuid: {},
        // 二维码展示文字
        qrCodeShowText: '',
        // 二维码输入文字
        qrCodeInputText: '',
        // 会员信息
        memberInfo: {},
        qrCodeList: []
    },
    getters,
    mutations,
    actions,
    modules() {}
})