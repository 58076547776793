import http from './http.js'


export function memberLogin() {
    return http.post("/member/memberLogin")
}

export function memberRegister(param) {
    return http.post("/member/memberRegister", param)
}



