<template>
  <div>
    <button @click="clickTest">测试按钮</button>
    <div>{{returnStr}}</div>
    <div>{{aaa}}</div>
    <el-card class="box-card" v-loading="loading" element-loading-text="拼命加载中"  element-loading-background="rgba(0, 0, 0, 0.8)">
      <template #header>
        <div class="card-header">
          <div @click="changeOperateType(1)" :class="operateType == 1 ? 'btn-sel' : ''" class="login-tab-btn">登录</div>
          <div @click="changeOperateType(2)" :class="operateType == 2 ? 'btn-sel' : ''" class="register-tab-btn">注册</div>
        </div>
      </template>
      <MemberLogin v-if="operateType == 1"></MemberLogin>
      <MemberRegister v-else @changeOperateType="changeOperateType" @changeLoading="changeLoading"></MemberRegister>
    </el-card>

  </div>
</template>

<script setup name="MemberMain">
import {ref,reactive,onMounted,computed} from 'vue'
import { useStore } from 'vuex'


const aaa = ref("aaaaaaaaaaaaaaaaaaaa")
const aaaCompile = ref('')
const aaaDeCompile = ref('')

import MemberLogin from "@/views/memberpage/memberLogin.vue";
import MemberRegister from "@/views/memberpage/memberRegister.vue";


const $store = useStore()

const memberInfo = ref(computed(() => {return $store.state.memberInfo}))
const isLogin = ref(computed(() => {return $store.state.memberInfo.memberId != undefined}))
const returnStr = ref('')
const operateType = ref(1)
const loading = ref(false)

const clickTest = () => {
}

const changeLoading = () => {
  loading.value = !loading.value
}
const changeOperateType = (type) => {
  console.log("changeOperateType,入参：", type)
  operateType.value = type
}

onMounted(() => {
    console.log("memberMainPage mounted")
    if (memberInfo.value.memberId) {
        console.log("会员已登录")
    } else {
        console.log("未登录")
    }
})

</script>
<style scoped>

.box-card {
  width: 40%;
  margin: 5% auto auto;
}
.card-header {
  display: flex;
}
.login-tab-btn {
  width: 50%;
  border-right: 1px solid rgb(228,231,237);
  text-align: center;
  cursor: pointer;

}
.login-tab-btn:hover {
  color: #409eff;
}
.btn-sel {
  color: #409eff;
}
.register-tab-btn {
  width: 50%;
  text-align: center;
  cursor: pointer;
}
</style>