<template>
  <div class="qrcode-main">
    <div class="qrcode-input-div">
      <el-input v-model="curQRCodeInputText" placeholder="请输入二维码内容" @input="changeInput" clearable></el-input>
      <el-button type="primary" class="store-btn" @click="storeQrCode">存到下面的框里</el-button>
      <el-button type="success" class="store-btn" @click="clearData">清空存储区数据</el-button>
    </div>
    <div class="qrcode-div">
      <qrcode-vue v-if="curQRCodeShowText != '' && curQRCodeShowText != undefined" :value="curQRCodeShowText" size="200"  ></qrcode-vue>
      <div v-else class="qrcode-div-holder">
        <div v-for="i in 5" style="margin-top: 5px">
          <img style="width: 30px" src="../../assets/logotemp.jpg">
          <img style="width: 30px;margin-left: 10px" src="../../assets/logotemp.jpg">
          <img style="width: 30px;margin-left: 10px" src="../../assets/logotemp.jpg">
          <img style="width: 30px;margin-left: 10px" src="../../assets/logotemp.jpg">
          <img style="width: 30px;margin-left: 10px" src="../../assets/logotemp.jpg">
        </div>
      </div>
      <h2>当前二维码内容：【{{curQRCodeShowText}}】</h2>
    </div>
    <div class="store-area">
      <el-card v-for="(item, index) in storeQrCodeList" class="box-card">
        <qrcode-vue :value="item" size="100"></qrcode-vue>
        <div style="font-size: 0.8rem;word-break: break-word">{{item}}</div>
      </el-card>
    </div>
  </div>
</template>

<script setup name="qrCodeMain">

import {computed, ref} from "vue";
import { useStore } from 'vuex'

import QrcodeVue from 'qrcode.vue'
import {ElMessage} from "element-plus";

const $store = useStore()
const curQRCodeShowText = ref($store.state.qrCodeShowText)
const curQRCodeInputText = ref($store.state.qrCodeInputText)
const storeQrCodeList = ref($store.state.qrCodeList)


const changeInput = () => {
  curQRCodeShowText.value = curQRCodeInputText.value
  $store.state.qrCodeShowText = curQRCodeShowText.value
  $store.state.qrCodeInputText = curQRCodeInputText.value
}

const storeQrCode = () => {
  if (!$store.state.qrCodeInputText || $store.state.qrCodeInputText === "") {
    ElMessage({
      type: 'error',
      message: '空值不能存哦！',
    })
    return
  }
  $store.state.qrCodeList.push($store.state.qrCodeInputText)
}

const clearData = () => {
  $store.state.qrCodeList = []
  storeQrCodeList.value = $store.state.qrCodeList
}

</script>
<style scoped>
  .qrcode-input-div {
    margin-top: 30px;
    text-align: center;
    .el-input {
      width: 600px;
    }
  }
  .qrcode-div {
    margin-top: 30px;
    text-align: center;
  }
  .qrcode-div-holder {
    margin: auto;
    text-align: center;
    width: 200px;
    height: 200px;
    border: 2px dashed;
  }
  .store-btn {
    margin-left: 1rem;
  }
  .store-area {
    margin-top: 1rem;
    display: flex;
  }
  .box-card {
    width: 10rem;
    margin-right: 2rem;
    height: 10rem;
    text-align: center;
  }

</style>