import { createRouter, createWebHistory } from "vue-router"

import Home from '../views/mainpage/home.vue'
import MoreMain from "@/views/morepage/moreMain.vue";
import JsonLabel from "@/views/jsonpage/jsonLabel.vue";
import MemberMain from "@/views/memberpage/memberMain.vue";
import QRCodeMain from "@/views/qrCodePage/qrCodeMain.vue"

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/JsonLabel',
            name: 'JsonLabel',
            component: JsonLabel
        },
        {
            path: '/MemberMain',
            name: 'MemberMain',
            component: MemberMain
        },
        {
            path: '/QRCodeMain',
            name: 'QRCodeMain',
            component: QRCodeMain
        },
        {
            path: '/MoreMain',
            name: 'MoreMain',
            component: MoreMain
        }
    ]
})

export default router