<template>
    <div class="json-main">
        <!-- json输入框       -->
<!--        {{activeTab.inputJsonStr}}-->
        <div class="edit-container">
            <el-input
                v-model="activeTab.inputJsonStr"
                :rows="rowNum"
                type="textarea"
                placeholder="请输入json"
                @input="changeInput"
            />
            <el-button class="btn0" @click="escapeJson" type="primary" plain>转义</el-button>
            <el-button class="btn1" @click="compressJson" type="primary" plain>压缩</el-button>
            <el-button class="btn2" @click="copyJson" type="primary" plain>复制</el-button>
            <el-button class="btn3" @click="callSth" type="primary" plain>召唤</el-button>
        </div>
        <!-- json格式化视图       -->
        <div class="json-view-container">
            <json-viewer :value="jsonViewText" copyable :expand-depth=10 class="jsonCss"/>
        </div>
    </div>

</template>

<script setup name="JsonMain">
import {ref,reactive,nextTick,watch,onMounted,computed} from 'vue'
import {commonUtils} from "@/libs/util";
import { useStore } from 'vuex'
import {ElMessage} from "element-plus";

const $store = useStore()

const jsonViewText = ref('请输入json')
const windowsState = reactive({
    hHeight: 0
})
const rowNum = ref(40)
const activeGroup = ref(computed(() => {return commonUtils.getActiveGroup($store.state.groupList, $store.state.activeGroupGuid)}))
const activeTab = ref(computed(() => {return commonUtils.getActiveTab(commonUtils.getActiveGroup($store.state.groupList, $store.state.activeGroupGuid))}))
// const inputJsonStr = ref('')
const btn3Color = ref("rgb(236,245,255)")
const btn3DefaultColor = ref("rgb(236,245,255)")
const btn3Loading = ref(false)

const changeInput = (val) => {
    console.log("jsonMain改变输入changeInput：", val)
    if (val && val.charAt(0) == "\"") {
        val = val.slice(1)
    }
    if (val && val.charAt(val.length - 1) == "\"") {
        val = val.slice(0, val.length - 1)
    }
    // 说明确实需要转义，否则可能是json对象里面包含了一个转义字符串
    if (val && val.startsWith('{\\"')) {
        val = val.replaceAll('\\"', '"')
    }
    if (val && commonUtils.checkIsJsonStr(val)) {
        jsonViewText.value = JSON.parse(val)
    } else {
        jsonViewText.value = "不是一个正确的json格式"
    }
    // activeTab.value.inputJsonStr = val
}

// 给jsonView的每个node添加一个按钮
const changeNode = () => {
  // setTimeout(() => {
  //   let nodeList = document.getElementsByClassName("jv-item");
  //   // let span = "<span>手动添加按钮</span>"
  //   for(let node of nodeList) {
  //     let innerHtml = undefined
  //     if (node.nextElementSibling) {
  //       innerHtml = node.nextElementSibling.innerHTML
  //       console.log(innerHtml)
  //     }
  //
  //     let spanElem = document.createElement("span")
  //     spanElem.innerText="替换左边Json数据"
  //     spanElem.className="nodeItemBtn"
  //     spanElem.onclick = function (e, innerHtml) {
  //       console.log("哈", innerHtml)
  //
  //       console.log(e)
  //       console.log("点击替换数据button", e.srcElement.parentNode.outerText)
  //       console.log(e.target.parentNode.outerText.replaceAll("替换左边Json数据", ""))
  //       activeTab.value.inputJsonStr = e.target.parentNode.outerText.replaceAll("替换左边Json数据", "")
  //       changeInput(e.target.parentNode.outerText.replaceAll("替换左边Json数据", ""))
  //     }
  //     // spanElem.style = "color:red;cursor:pointer;"
  //     node.insertAdjacentElement("afterend", spanElem)
  //   }
  //   console.log(nodeList)
  // }, 1000);
}

// 压缩json
const compressJson = () => {
    activeTab.value.inputJsonStr = JSON.stringify(activeTab.value.inputJsonStr)
        .replaceAll("\\n", "")
        .replaceAll("\\\"", "\"")
        .replace(/\s/g, "")
    ElMessage({
        message: '压缩成功',
        type: 'success',
    })
}

// 转义
const escapeJson = () => {
    activeTab.value.inputJsonStr = JSON.stringify(activeTab.value.inputJsonStr)
        .replaceAll("\\n", "")
        .replaceAll("\\\"", "\"")
        .replace(/\s/g, "")
    activeTab.value.inputJsonStr = JSON.stringify(activeTab.value.inputJsonStr)
}

// 复制json
const copyJson = () => {
    // navigator.clipboard.writeText(activeTab.value.inputJsonStr).then(() => {
    //     ElMessage({
    //         message: '复制成功',
    //         type: 'success',
    //     })
    // }).catch(err => {
    //     ElMessage({
    //         message: '复制失败',
    //         type: 'error',
    //     })
    // })
    const textarea = document.createElement('textarea');
    // 设置相关属性
    textarea.readOnly = 'readonly';
    textarea.style.position = 'fixed';
    textarea.style.top = '-99999px';
    // 把目标内容赋值给它的value属性
    textarea.value = activeTab.value.inputJsonStr;
    // 插入到页面
    document.body.appendChild(textarea);
    // 调用onselect()方法
    textarea.select();

    // const success = binding.arg;
    // 把目标内容复制进剪贴板, 该API会返回一个Boolean
    const res = document.execCommand('Copy');
    // res && success ? success(el.targetContent) : ''
    // 移除textarea标签
    document.body.removeChild(textarea);
    if (res) {
        ElMessage({
            message: "复制成功",
            type: 'success',
        })
    } else {
        ElMessage({
            message: "复制失败",
            type: 'error',
        })
    }


}

// 召唤什么
const callSth = () => {
    ElMessage({
        message: '正在召唤中...',
        type: 'warning',
    })
    let motto = commonUtils.randomMotto(111)
    let delayTime = 100
    btn3Loading.value = true
    let interval1 = setInterval(() => {
        btn3Color.value = commonUtils.randomColor()
    }, delayTime)
    // let interval2 = setInterval(() => {
    //     ElMessage({
    //         message: '正在召唤中...',
    //         type: 'warning',
    //     })
    // }, 1500)
    setTimeout(() => {
        clearInterval(interval1)
        // clearInterval(interval2)
        ElMessage({
            message: motto,
            type: 'success',
        })
        btn3Color.value = btn3DefaultColor.value
    }, 5000)

}

const replaceInputText = (text) => {
}

onMounted(() => {
    console.log("jsonMain mounted")
    console.log("activeTab", activeTab)
    if (null == activeTab.value.inputJsonStr || "" == activeTab.value.inputJsonStr) {
        console.log("判断1")
        jsonViewText.value = "请输入json"
        activeTab.value.inputJsonStr = ""
    } else {
        console.log("判断2")
        jsonViewText.value = activeTab.value.inputJsonStr
        // activeTab.value.inputJsonStr = activeTab.value.inputJsonStr
    }
    if (activeTab.value.inputJsonStr == "请输入json") {
        console.log("判断3")
        activeTab.value.inputJsonStr = ""
    }
    changeInput(jsonViewText.value)

})

// 动态改变编辑框高度
const changeTextAreaRow = () => {
    rowNum.value = Math.ceil((windowsState.hHeight - 20) / 25)
}

// 页面初始化时，获得页面高度
nextTick(()=>{
    windowsState.hHeight = document.documentElement.clientHeight;
    changeTextAreaRow()
})

// 窗口改变时，拿到页面高度
window.onresize = () => {
    return (
        () => {
            windowsState.hHeight = document.documentElement.clientHeight;
        }
    )()
}

// 监听窗口高度变化
watch(windowsState, (curVal, preVal) => {
    changeTextAreaRow()
}, {deep: true})

watch(jsonViewText, (curVal, preVal) => {
  console.log("选中的tab发生变化，现在的" + curVal + "|以前的：" + preVal)
  changeNode()
}, {deep: true})

</script>
<style scoped>

.json-main {
    display: flex;
}

.jsonCss{
    max-height: 500px;
    overflow: auto;
    max-height: 100%;
    background-color: #F0FFFF;
}

.edit-container {
    width: 50%;
    position: relative;
}

.jv-container.jv-light {
    position: absolute;
    width: 50%;
}


.jv-container.jv-light > .jv-button {
    position: fixed;
    right: 15%;
    font-size: 3em !important;
}

.el-textarea__inner:focus {
    border-color: transparent !important;
}


.el-textarea {
    --el-input-focus-border: rgb(192,196,204) !important;
    --el-input-focus-border-color: rgb(192,196,204) !important;
}

:deep .jv-code {
    background-color: #F0FFFF;
}

:deep .jv-key:hover {
  border: 1px dashed black;
  background-color: #42b983;
  font-weight: bold;
}

:deep .jv-item:hover {
  border: 1px dashed black;
  background-color: #42b983;
  color: white !important;
  font-weight: bold;
}

:deep .jv-key-node .nodeItemBtn {
  display: none;
}

:deep .jv-key-node:hover  .nodeItemBtn {
  display: inline;
  color: yellowgreen;
  cursor: pointer;
  font-size: 12px;
  border: 1px solid;
  margin-left: 10px;
}

:deep .nodeItemBtn:hover {
  color: white;
  background-color: #42b983;
  font-weight: bold;
}

:deep .jv-push .nodeItemBtn {
  display: none;
}

.btn0 {
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 1rem;
}

.btn1 {
    position: absolute;
    z-index: 1;
    top: 3.5rem;
    right: 1rem;
}

.btn2 {
    position: absolute;
    z-index: 1;
    top: 6rem;
    right: 1rem;
}

.btn3 {
    position: absolute;
    z-index: 1;
    top: 8.5rem;
    right: 1rem;
}

:deep .btn3 {
    background-color: v-bind('btn3Color')
}

/*:deep .btn3:hover {
    background-color: rgb(64,158,255);
}*/

:deep .btn3 > span {
    color: rgb(64,158,255);
}

/*:deep .btn3:hover > span {
    color: white;
}*/

</style>