<template>
  <div>
    <h1>HOME</h1>
  </div>
</template>

<script setup name="home">
import {ref,reactive,onMounted} from 'vue'
import { useRouter } from 'vue-router'

const $router = useRouter()

onMounted(() => {
    $router.push('JsonLabel')
})
</script>
<style scoped>

</style>