<template>
    <div class="app">
        <div class="header">
            <navBar ref="navBar"></navBar>
        </div>
        <div class="content">
            <router-view />
        </div>
    </div>
</template>

<script setup name="app">
import navBar from './views/mainpage/nav.vue'
import { reactive, ref,computed } from "vue";
import { useStore } from 'vuex'
import {commonUtils} from "@/libs/util";

const $store = useStore()

// for (let obj of $store.state.groupList) {
//     if (obj.isActive) {
//         $store.state.activeGroupGuid = obj.guid
//         $store.state.groupList[0].activeTabGuid = $store.state.groupList[0].tabList[0].guid
//     }
// }

// 初始化group和tab
let newTabObj = {
    title: "Tab  " + "1",
    name: commonUtils.generateUUID(),
    highLightColor: "black",
    inputJsonStr: "请输入json"
}
let newGroupObj = {
    name: "暂存区",
    guid: commonUtils.generateUUID(),
    isActive: true,
    isFixed: true,
    tabList: [newTabObj],
    activeTabGuid: newTabObj.name,
    tabNameSerial: 2
}
$store.state.groupList.push(newGroupObj)
$store.state.activeGroupGuid = newGroupObj.guid


</script>

<style scoped>
.app {
    margin: 0px;
    padding: 0px;
    width: 100%;
}
.content {
    padding-top: 10px;
    margin: 0px 20px 0px 20px;
}

</style>
