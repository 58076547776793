<template>
  <div class="json-label-container" style="display: flex">
     <!-- tab group -->
      <div>
          <JsonLabelGroup></JsonLabelGroup>
      </div>
      <!-- tab标签页 -->
      <div style="width: 100%;margin-left: 20px;">
          <el-tabs
              v-model="activeGroup.activeTabGuid"
              type="card"
              editable
              class="demo-tabs"
              @edit="handleTabsEdit"
          >
              <el-tab-pane
                  label="默认集合-右键标签解锁新姿势-->"
                  name="first"
                  :closable="false"
                  disabled
              >
              </el-tab-pane>
              <el-tab-pane
                  v-for="item in activeGroup.tabList"
                  :key="item.name"
                  :label="item.title"
                  :name="item.name"
              >
                  <template #label>
                      <el-dropdown
                          trigger="contextmenu"
                          :id="item.name"
                          ref="dropDownRef"
                          :class="item.highLightColor"
                      >
                          <span>{{item.title}}</span>
                          <template #dropdown>
                              <el-dropdown-menu>
                                  <el-dropdown-item @click="editTabName(item.name, item.title)">
                                      <el-icon><Edit /></el-icon>标签重命名
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="removeTab(item.name, 'right')" style="color: gainsboro">
                                      <el-icon><ArrowRightBold /></el-icon>关闭右侧标签页
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="removeTab(item.name, 'left')" style="color: gainsboro">
                                      <el-icon><ArrowLeftBold /></el-icon>关闭左侧标签页
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="removeTab(item.name, 'other')" style="color: gainsboro">
                                      <el-icon><More /></el-icon>关闭其它标签页
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="removeTab(item.name, 'all')" style="color: gainsboro">
                                      <el-icon><CloseBold /></el-icon>关闭全部标签页
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="highLightTab(item.name, 'red')">
                                      <el-icon><StarFilled /></el-icon>
                                      <span>高亮：</span>
                                      <span style="color: #E3170D">红色</span>
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="highLightTab(item.name, 'blue')">
                                      <el-icon><StarFilled /></el-icon>
                                      <span>高亮：</span>
                                      <span style="color: #4169E1">蓝色</span>
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="highLightTab(item.name, 'green')">
                                      <el-icon><StarFilled /></el-icon>
                                      <span>高亮：</span>
                                      <span style="color: #228B22">绿色</span>
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="highLightTab(item.name, 'black')">
                                      <el-icon><StarFilled /></el-icon>
                                      <span>高亮：黑色</span>
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="saveDataCloud(item)" style="color: gainsboro">
                                      <el-icon><UploadFilled /></el-icon>
                                      <span>存储该数据到云端</span>
                                  </el-dropdown-item>
                                  <el-dropdown-item @click="compareTab(item)">
                                      <el-icon><DataAnalysis /></el-icon>
                                      <span>对比多个json</span>
                                  </el-dropdown-item>
                              </el-dropdown-menu>
                          </template>
                      </el-dropdown>
                  </template>
                  <JsonMain :ref="item.name"></JsonMain>
              </el-tab-pane>
          </el-tabs>
      </div>


    <!--  选择需要对比的tab    -->
      <el-dialog
          v-model="compareTabSelectDialogShow"
          title="选择需要对比的json"
          width="30%"
          :before-close="handleCompareTabSelectDialogClose"
      >
          <el-checkbox-group v-model="selectedTabsCheckbox" :min="0" :max="4" @change="changeCompareTabs">
              <el-checkbox v-for="editableTab in activeGroup.tabList" :key="editableTab.name" :label="editableTab.name">{{
                      editableTab.title
                  }}</el-checkbox>
          </el-checkbox-group>
          <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleCompareTabSelectDialogClose">取消</el-button>
            <el-button type="primary" @click="confirmSelectCompareTab">
              确定
            </el-button>
          </span>
          </template>
      </el-dialog>

    <!--  JSON比较视图    -->
      <el-dialog
          class="compare-select-dialog"
          v-model="compareDialogShowFlag"
          width="95%"
          :before-close="handleCompareDialogClose"
          fullscreen
          modal
          show-close
      >
          <div slot="title" class="compare-select-title">
              <div style="font-size:18px;color:#38b48b;font-weight: bold;">JSON对比</div>
              <el-button type="danger" @click="handleCompareDialogClose">关闭页面</el-button>
          </div>
        <JsonCompare ref="jsonCompare" :compareDialogShowFlag="compareDialogShowFlag"></JsonCompare>
      </el-dialog>
  </div>
</template>

<script setup name="jsonLabel">
import {ref,reactive,onMounted,getCurrentInstance,computed } from 'vue'
import JsonMain from "@/views/jsonpage/jsonMain.vue";
import JsonCompare from "@/views/jsonpage/jsonCompare.vue";
import JsonLabelGroup from "@/views/jsonpage/jsonLabelGroup.vue";
import {commonUtils} from "@/libs/util";
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'


const $store = useStore()

const selectedTabs = ref($store.state.selectedTabs)
// 被选择需要对比的tab
const selectedTabsCheckbox = ref([])
// 选择需要对比的tab模态框展示
const compareTabSelectDialogShow = ref(false)
// 不同的tab比对展示视图
const compareDialogShowFlag = ref(false)
const activeGroup = computed(() => {return commonUtils.getActiveGroup($store.state.groupList, $store.state.activeGroupGuid)})
const activeTab = ref(computed(() => {return commonUtils.getActiveTab(commonUtils.getActiveGroup($store.state.groupList, $store.state.activeGroupGuid))}))
const groupList = computed(() => {return $store.state.groupList})


const { proxy } = getCurrentInstance();



const changeCompareTabs = (val) => {
    $store.state.selectedTabs = val
}

// 关闭 选择需要对比的tab 时的事件处理
const handleCompareTabSelectDialogClose = () => {
    compareTabSelectDialogShow.value = false
    selectedTabsCheckbox.value = []
    $store.state.selectedTabs = []
}

const confirmSelectCompareTab = () => {
    compareDialogShowFlag.value = true
    compareTabSelectDialogShow.value = false
}

// 关闭用来比较的dialog
const handleCompareDialogClose = () => {
    compareDialogShowFlag.value = false
}


// 修改标签名
const editTabName = (tabName, tabTitle) => {
    ElMessageBox.prompt('请输入新的标签名：', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: tabTitle
    })
        .then(({ value }) => {
            activeTab.value.title = value
            ElMessage({
                type: 'success',
                message: '修改成功',
            })
        })
        .catch((e) => {
            console.log(e)
            ElMessage({
                type: 'info',
                message: '修改失败' + e,
            })
        })
}

// 移除标签
const removeTab = (tabName, operateName) => {
    ElMessage({
        message: '功能开发中',
        type: 'warning',
    })
}

// 高亮标签
const highLightTab = (tabName, colorVal) => {
    activeTab.value.highLightColor = colorVal
}

// 保存数据到云端
const saveDataCloud = (item) => {
    ElMessage({
        message: '功能开发中',
        type: 'warning',
    })
}

// 对比多个json数据
const compareTab = (item) => {
    handleCompareTabSelectDialogClose()
    compareTabSelectDialogShow.value = true
}

// 添加或删除tab
const handleTabsEdit = (targetName,action) => {
    console.log("调用handleTabsEdit 方法，add tab or remove tab")
    if (action === 'add') {
        const newTabName = commonUtils.generateUUID()
        activeGroup.value.tabList.push({
            title: "Tab  " + activeGroup.value.tabNameSerial,
            name: newTabName,
            highLightColor: 'black',
            inputJsonStr: '',
            ref: ref(null)
        })
      activeGroup.value.tabNameSerial = activeGroup.value.tabNameSerial + 1
        activeGroup.value.activeTabGuid = newTabName
    } else if (action === 'remove') {
        let activeTabList = activeGroup.value.tabList
        let activeTabGuid = activeGroup.value.activeTabGuid
        for (let index = activeTabList.length - 1; index >= 0; index--) {
            if (activeTabList[index].name == targetName) {
                const nextTab = activeTabList[index + 1] || activeTabList[index - 1]
                if (nextTab && activeTabGuid == targetName) {
                    activeGroup.value.activeTabGuid = nextTab.name
                }
                activeTabList.splice(index, 1)
                break
            }
        }
        activeGroup.value.tabList = activeTabList
    }
}




</script>
<style scoped>

.demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

:deep(.el-tabs__item.is-disabled) {
}

:deep(.el-tabs__item.is-active) {
    border: 2px dashed black !important;
    font-weight: bold;
}

:deep(.black) {
    color: black;
}
:deep(.red) {
    color: #E3170D;
}
:deep(.blue) {
    color: #4169E1;
}
:deep(.green) {
    color: #228B22;
}
:deep(.el-tabs__new-tab) {
    border: 2px solid black;
    margin: 10px 30px 10px 10px;
}

:deep(.compare-select-dialog > .el-dialog__header) {
    display: none;
}

:deep(.compare-select-dialog > .el-dialog__body) {
    padding-top: 0px;
}

.compare-select-title {
    padding: 10px 0px 10px 0px;
    display: flex;
}

.compare-select-title > button {
    margin-left: 90px;
}

:deep(.el-card__body) {
    padding: 0px;
}


</style>