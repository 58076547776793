<template>
  <div>
    <div class="register-div">
      <el-form :model="registerForm" label-width="120px">
        <el-form-item label="昵称">
          <el-input v-model="registerForm.nickName" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="registerForm.password" />
        </el-form-item>
        <el-form-item label="密码确认">
          <el-input type="password" v-model="registerForm.passwordConfirm" />
        </el-form-item>
      </el-form>
      <el-button @click="register" class="register-btn" type="primary">确认注册</el-button>
    </div>
  </div>
</template>

<script setup name="MemberLogin">
import {ref, reactive,defineEmits  } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {memberRegister} from "@/api/MemberApi";
import { useStore } from 'vuex'

const emit = defineEmits(['changeOperateType', 'changeLoading'])
const $store = useStore()

const registerForm = ref({})

const register = () => {
  console.log("点击注册")
  let judge = formVerify()
  console.log("注册表单验证结果", judge)
  if (!judge) {
    return
  }
  changeLoading()
  memberRegister(registerForm.value)
      .then((res) => {
        console.log("memberRegister返回值", JSON.stringify(res))
        changeLoading()
        if (res.code != 200) {
          ElMessage({
            type: 'error',
            message: res.message,
          })
        } else {
          $store.state.memberInfo = res.data
          ElMessage({
            type: 'success',
            message: res.message,
          })
        }
      })
      .catch(err => {
        console.log(err)
        changeLoading()
        ElMessage({
          type: 'error',
          message: err,
        })
      })

}

const changeOperateType = () => {
  emit('changeOperateType', 1)
}

const changeLoading = () => {
  emit('changeLoading')
}

const formVerify = () => {
  if (!registerForm.value.nickName) {
    ElMessage({
      type: 'error',
      message: '昵称为空！',
    })
    return false
  }
  if (!registerForm.value.password) {
    ElMessage({
      type: 'error',
      message: '密码为空！',
    })
    return false
  }
  if (!registerForm.value.passwordConfirm) {
    ElMessage({
      type: 'error',
      message: '确认密码为空！',
    })
    return false
  }
  if (registerForm.value.passwordConfirm != registerForm.value.password) {
    ElMessage({
      type: 'error',
      message: '两次输入的密码不一致！',
    })
    return false
  }

  // memberRegister()
  //     .then((res) => {
  //       console.log("getCaptcha返回值", JSON.stringify(res))
  //       returnStr.value = returnStr.value + res + " ||| "
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       returnStr.value = returnStr.value + err
  //     })

  return true
}

</script>

<style scoped>
.register-btn {
  float: right;
  margin-bottom: 10px;
}
</style>
