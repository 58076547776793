<template>
  <div class="json-compare">
      <el-card class="box-card card-one" v-for="(item,index) in compareJsonArr">
          <template #header>
              <div class="card-header">
                  <span style="font-weight: bold">{{ item.title }}</span>
              </div>
          </template>
          <json-viewer v-if="index == 0" :style="jsonViewStyle1" :value="item.inputJsonStr" copyable :expand-depth=10 />
          <json-viewer v-if="index == 1" :style="jsonViewStyle2" :value="item.inputJsonStr" copyable :expand-depth=10 />
          <json-viewer v-if="index == 2" :style="jsonViewStyle3" :value="item.inputJsonStr" copyable :expand-depth=10 />
          <json-viewer v-if="index == 3" :style="jsonViewStyle4" :value="item.inputJsonStr" copyable :expand-depth=10 />
      </el-card>
  </div>
</template>

<script setup name="jsonCompare">
import {ref,reactive,defineProps,toRefs,watch,onMounted,computed} from 'vue'
import { useStore } from 'vuex'
import {commonUtils} from "@/libs/util";

const $store = useStore()

const props = defineProps({
    //子组件接收父组件传递过来的值
    compareDialogShowFlag: Boolean
})

const selectedTabs = computed(() => {return $store.state.selectedTabs})

const {compareDialogShowFlag} = toRefs(props)
// 待比较的数据
let compareJsonArr = ref([])

const jsonViewStyle1 = ref("max-height: 750px;overflow: auto;")
const jsonViewStyle2 = ref("max-height: 750px;overflow: auto;")
const jsonViewStyle3 = ref("max-height: 750px;overflow: auto;")
const jsonViewStyle4 = ref("max-height: 750px;overflow: auto;")

// 构建比较数据
const buildCompareData = () => {
    let editableTabs = commonUtils.getActiveGroup($store.state.groupList, $store.state.activeGroupGuid).tabList
    compareJsonArr.value = []
    for (let itemName of selectedTabs.value) {
        for (let tabIter of editableTabs) {
            if (tabIter.name == itemName) {

                let inputJsonStrTemp = tabIter.inputJsonStr

                if (inputJsonStrTemp.charAt(0) == "\"") {
                    inputJsonStrTemp = inputJsonStrTemp.slice(1)
                }
                if (inputJsonStrTemp.charAt(inputJsonStrTemp.length - 1) == "\"") {
                    inputJsonStrTemp = inputJsonStrTemp.slice(0, inputJsonStrTemp.length - 1)
                }
                // 说明确实需要转义，否则可能是json对象里面包含了一个转义字符串
                if (inputJsonStrTemp.startsWith('{\\"')) {
                    inputJsonStrTemp = inputJsonStrTemp.replaceAll('\\"', '"')
                }
                if (commonUtils.checkIsJsonStr(inputJsonStrTemp)) {
                    inputJsonStrTemp = JSON.parse(inputJsonStrTemp)
                } else {
                    inputJsonStrTemp = "不是一个正确的json格式"
                }

                let obj = {
                    inputJsonStr: inputJsonStrTemp,
                    title: tabIter.title
                }
                compareJsonArr.value.push(obj)
            }
        }
    }
    console.log("需要比较的数据数组", compareJsonArr.value)
}

onMounted(() => {
    buildCompareData()
    console.log("mounted")
})

watch(compareDialogShowFlag, (curVal, preVal) => {
    console.log("选中的tab发生变化，现在的" + curVal + "|以前的：" + preVal)
    buildCompareData()
}, {deep: true})


</script>
<style scoped>

.json-compare {
    display: flex;
}

/*.json-view-container {*/
/*    width: 30%;*/
/*}*/

.jsonCss{
    max-height: 700px;
    overflow: auto;
}

.jv-container.jv-light {
    /*position: absolute;*/
    /*width: 25%;*/
}


.box-card {
    width: 25%;
    min-height: 750px;
    margin-left: 5px;
}

.box-card :deep .el-card__body {
    padding: 0px;
}

</style>